import lightGallery from "lightgallery";

// Datos cambios automaticamente por increment-version.js y updateversionDate.js
export const environment = {
  production: true,
  versionDate: "2024-10-08", 
  versionNumber: "5.5.29",
  lightGalleryLicenceKey: "4EE45E0A-78DD-4138-93C6-B636B1C61622",
};
// WEBAPI
export const API_URL = "https://kflow.cmsmultiservicios.com/api";

export const VERSION = "v1";