import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { STORAGE_REQ_KEY } from './offline-manager.service';
import { SERVICE_SV_EXTRA_KEY } from '../05-shared/utils/Contanst';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private storage: Storage
  ) { 

    this.initDatabase();
  }
  private async initDatabase() {
    await this.storage.create();
    //console.log('Database initialized');
  }

  set(key: string, value: any) {
    if (key === SERVICE_SV_EXTRA_KEY) {
      if (!Array.isArray(value)) {
        throw new Error('No se puede guardar un array en el storage');
      }
    }
    return this.storage.set(key, JSON.stringify(value));
  }

  async getValueForKey(key: string) {
    try {
      const result = await this.storage.get(key);
      if (result === null || result === undefined) {
        // Opcional: Inicializa la clave con un valor predeterminado
        const defaultValue = []; // o cualquier valor predeterminado que necesites
        await this.storage.set(key, defaultValue);
        return defaultValue;
      }
      return result;
    } catch (error) {
      console.error(`ERROR: No se pudo obtener el valor para la clave ${key}.`, error);
      throw error; // O maneja el error de otra manera
    }
  }

  async get(key: string) {
    const exits = await this.getValueForKey(key);
    if (exits) {
      return this.storage.get(key).then((result) => {
        if (result === null || result === undefined || typeof result !== 'string') {
          return null;
        }
        return JSON.parse(result);
      }).catch((error) => {
        console.error('Error getting item from database', error);
        return null;
      });
    }
  }

  remove(key: string) {
    return this.storage.remove(key);
  }

  clear() {
    return this.storage.clear();
  }
}
